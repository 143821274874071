<template>
    <div :id="blok._uid"
        class="flex flex-col px-sm py-lg md:px-2xl">
        <div class="mx-auto my-lg">
            <v-icon class="w-auto text-12xl text-red-500"
                :icon="iconName" />
        </div>

        <div class="mb-2">
            <p class="text-center font-sans-narrow text-xl font-bold">{{ blok?.title }}</p>
        </div>

        <div>
            <p class="text-center">{{ blok?.content }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-product-safety' | 'sb-product-safety-container' }>
}>();

const iconName = computed(() => properties.blok.icon);
</script>
